import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';



const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID
apiKey: "AIzaSyBxi9KJRda8Oq-kv3Nk-E4m_3YpJ_9t_zc",
  authDomain: "cassafi.firebaseapp.com",
  projectId: "cassafi",
  storageBucket: "cassafi.appspot.com",
  messagingSenderId: "999429122710",
  appId: "1:999429122710:web:d4099aaf50c3e46081b0b7"
};

console.log(process.env.REACT_APP_FIREBASE_API_KEY);


console.log(firebaseConfig);

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
