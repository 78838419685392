import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './Login';
import './App.css';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import HomeBuyingTracker from './HomeBuyingTracker';

const HomeBuyingTracker2 = ({ currentStep }) => {
  const steps = [
    { name: 'Pre-Approval', icon: '💰' },
    // { name: 'Home Search', icon: '🔍' },
    // { name: 'Offer & Negotiation', icon: '📝' },
    // { name: 'Home Inspection', icon: '🏠' },
    // { name: 'Appraisal', icon: '📊' },
    // { name: 'Loan Processing', icon: '📄' },
    // { name: 'Title Search', icon: '🔎' },
    // { name: 'Final Approval', icon: '✅' },
    // { name: 'Closing', icon: '🔑' },
    // { name: 'Move-In', icon: '🚚' },
  ];

  return (
    <div style={{ maxWidth: '100%', padding: '10px', backgroundColor: '#1E2A3A ', borderRadius: '8px', overflowX: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: 'max-content' }}>
        {steps.map((step, index) => (
          <div key={step.name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 5px' }}>
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: step.name === currentStep ? '#3b82f6' : '#e5e7eb',
                color: step.name === currentStep ? 'white' : '#6b7280',
                fontSize: '20px',
                marginBottom: '5px',
              }}
            >
              {step.icon}
            </div>
            <p style={{ 
              fontSize: '10px', 
              textAlign: 'center', 
              fontWeight: step.name === currentStep ? 'bold' : 'normal',
              color: step.name === currentStep ? '#3b82f6' : 'white',
            }}>
              {step.name}
            </p>
            <div>You're now at the pre-approval stage. Focus on questions about getting pre-approved for your mortgage. Need help with other home-buying steps? We're here to assist!</div>
          </div>
        ))}
      </div>
    </div>
  );
};

function App() {
  const agentImage = 'https://s3.amazonaws.com/media.mixrank.com/profilepic/35216f3880fec667a72de1eb5b781bb3'
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const currentStep = 'Pre-Approval'; // Hardcoded to Pre-Approval

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserEmail(user.email);
        setTimeout(() => {
          setMessages(prevMessages => [...prevMessages, { text: " 👋 Hey there -- welcome back! I'm here to help answer any questions you may have about real estate in Phoenix, Arizona.", user: false }]);
          setIsLoadingResponse(false);
        }, 750);
      } else {
        setIsLoggedIn(false);
        setUserEmail('');
        setMessages([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const contacts = [
    { name: 'ELAN', status: 'Essential Local Area Navigator' },
  ];

  const handleLogin = (user) => {
    setIsLoggedIn(true);
    setUserEmail(user.email);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setUserEmail('');
      setMessages([]);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { text: input, user: true };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setIsLoadingResponse(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/chat`, { message: input, userId: auth.currentUser.uid });
      setMessages(prevMessages => [...prevMessages, { text: response.data.message, user: false }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, { text: 'Sorry, there was an error processing your message. Please email support@cassafi.com for further assistance.', user: false }]);
    } finally {
      setIsLoadingResponse(false);
    }
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="app">
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>Chat</h2>
        </div>
        <div className="contact-list">
          {contacts.map((contact, index) => (
            <div key={index} className="contact-item">
              <img src={agentImage} alt={contact.name} className="avatar" />
              <div className="contact-info">
                <h3>{contact.name}</h3>
                <p>{contact.status}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='sidebar-logo'>
          <img src={`https://wixmp-fe53c9ff592a4da924211f23.wixmp.com/users/df9807cc-9ec3-4aac-8629-301af0b4d474/design-previews/f97f02d7-8942-475e-858b-0931cadc784f/1718321361048-transparentThumbnail.png`} alt={'logo'} />
          <div style={{fontSize: '12px', color: 'gray', paddingTop: '5px'}}>
            ⚙️ Version -- pre-alpha
          </div>
          <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
        </div>
      </div>
      <div className="main-content">
        <div className="chat-header">
          <div className="header-icons">
            {/* header icons will go here */}
          </div>
          <div className="user-info">
            <span>{userEmail}</span>
            <div className="avatar-letter">{userEmail[0].toUpperCase()}</div>
          </div>
        </div>
        {/* <HomeBuyingTracker currentStep={currentStep} /> */}
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.user ? 'user' : 'bot'}`}>
              <div className="message-content">{message.text}</div>
            </div>
          ))}
          {isLoadingResponse && (
            <div className="message bot">
              <div className="message-content loading-dots">
                <span>.</span><span>.</span><span>.</span>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit} className="message-input">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Write your message here..."
          />
          <button type="submit">📎</button>
        </form>
      </div>
    </div>
  );
}

export default App;